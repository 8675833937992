import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'

import 'dayjs/locale/nl'
import 'dayjs/locale/en'

dayjs.extend(utc)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)

export { dayjs }
